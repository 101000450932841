<template>
  <v-dialog
    v-model="dialog"
    max-width="290"
  >
    <template #activator="{ on }">
      <v-icon
        class="ml-2"
        data-cy="delete-member"
        v-on="on"
      >
        {{ deleteIcon }}
      </v-icon>
    </template>
    <v-card data-cy="delete-member-dialog">
      <v-card-title>
        <span v-t="'team.doYouReallyWantToDeleteTheTeamMember'" />
        <span v-text="`(${teamMember.name || teamMember.email})`" />
      </v-card-title>
      <v-card-actions>
        <v-spacer />

        <v-btn
          rounded
          color="error"
          depressed=""
          data-cy="delete-member-dialog-yes"
          @click="onClickYes"
        >
          {{ $t('team.yes') }}
        </v-btn>

        <v-btn
          rounded
          depressed=""
          data-cy="delete-member-dialog-no"
          @click="closeDialog()"
        >
          {{ $t('team.no') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { DELETE_ICON } from '../../../constants/app'
export default {
  props: {
    teamMember: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      deleteIcon: DELETE_ICON,
      dialog: false
    }
  },
  methods: {
    ...mapActions('member', ['deleteTeamMember']),
    onClickYes () {
      this.deleteTeamMember(this.teamMember.id)
      this.closeDialog()
    },
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>
